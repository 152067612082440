
import {
  defineComponent, reactive, onBeforeMount, watch,
} from 'vue';
import ServicoNotaFiscal from '@/servicos/Fiscal/ServicoNotaFiscal';
import { IColumn } from '@/core/models/AntDesign/IColumn';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { EFiltroPeriodoMovimentoComercial } from '@/models/Enumeradores/Compartilhados/EFiltroPeriodoMovimentoComercial';
import SelecionarFiltroPeriodoMovimentoComercial from '@/components/Compartilhados/SelecionarFiltroPeriodoMovimentoComercial.vue';
import Card from '@/core/components/Tela/Card.vue';
import BuscaRapida from '@/core/components/BuscaRapida.vue';
import Icone from '@/core/components/Icone.vue';
import { IDTONFeContraCnpj } from '@/models/DTO/Fiscal/NotasFiscais/IDTONotaFiscalContraCnpj';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { useTelaBase } from '@/core/composables/TelaBase';
import SelecionarStatusContraNota from './SelecionarStatusContraNota.vue';
import { EStatusContraNota } from '@/models/Enumeradores/Compras/EStatusContraNota';
import SelecionarDataInicialFinal from '@/core/components/Tela/SelecionarDataInicialFinal.vue';

export default defineComponent({
  name: 'RecebimentoMercadoriasNotaContraCnpj',
  props: {
    empresa: {
      type: Number,
      required: true,
    },
  },
  components: {
    Card,
    Icone,
    BuscaRapida,
    SelecionarFiltroPeriodoMovimentoComercial,
    SelecionarStatusContraNota,
    MensagemSemDados,
    SelecionarDataInicialFinal,
  },
  emits: ['importarNfe'],
  setup(props, { emit }) {
    const servicoNotaFiscal = new ServicoNotaFiscal();

    const { apresentarMensagemAlerta, apresentarMensagemSucesso } = useTelaBase();

    const state = reactive({
      valorBusca: '',
      apresentarModalManifestacao: false,
      codigoNotaContraCnpjManifestacao: 0,
      codigoEvento: 210210,
      filtroPeriodo: {} as EFiltroPeriodoMovimentoComercial,
      status: {} as EStatusContraNota,
      buscandoNotas: false,
      notasContraCnpj: [] as IDTONFeContraCnpj[],
      telaMobile: false,
      importando: false,
      colunasGrade: [] as IColumn[],
      dataInicial: '',
      dataFinal: '',
    });

    const radioStyle = reactive({
      display: 'flex',
      height: '30px',
      lineHeight: '30px',
    });

    state.filtroPeriodo = EFiltroPeriodoMovimentoComercial.Ultimos30Dias;
    state.status = EStatusContraNota.Todos;

    async function importarNFe(codigoNotaContraCnpj: number) {
      if (UtilitarioGeral.validaCodigo(codigoNotaContraCnpj)) {
        const recebimento = await servicoNotaFiscal.importarNotaContraCnpj(codigoNotaContraCnpj, props.empresa);
        if (UtilitarioGeral.objetoValido(recebimento)) {
          if (UtilitarioGeral.valorValido(recebimento.notaFiscal.chaveAcesso)) {
            recebimento.codigoNotaCNPJ = codigoNotaContraCnpj;
            emit('importarNfe', recebimento);
            return;
          }
        }
      }
      apresentarMensagemAlerta('Não foi possível importar a NF-e, tente novamente daqui a pouco.');
    }

    function apresentarModalManifestacao(codigoNotaContraCnpj:number) {
      state.codigoNotaContraCnpjManifestacao = codigoNotaContraCnpj;
      state.codigoEvento = 210210;
      state.apresentarModalManifestacao = true;
    }

    async function manifestarNFe() {
      if (UtilitarioGeral.validaCodigo(state.codigoNotaContraCnpjManifestacao)) {
        const retornoComunicacaoDfe = await servicoNotaFiscal.manifestarNotaContraCnpj(state.codigoNotaContraCnpjManifestacao, props.empresa, state.codigoEvento);
        if (retornoComunicacaoDfe.sucesso) {
          apresentarMensagemSucesso('Manifestação concluída com sucesso!');
          const indexNotaContraCnpj = state.notasContraCnpj.findIndex((c) => c.codigo === state.codigoNotaContraCnpjManifestacao);
          if (indexNotaContraCnpj >= 0) {
            state.notasContraCnpj[indexNotaContraCnpj].manifestado = true;
          }
        } else {
          apresentarMensagemAlerta('Não foi possível manifestar a NF-e, tente novamente daqui a pouco.');
        }
      }
    }

    function preencherColunas() {
      if (state.telaMobile) {
        state.colunasGrade = [
          {
            title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false,
          },
          {
            title: 'Nota Fiscal', dataIndex: 'item', key: 'Item', position: 2, visible: true, ellipsis: true,
          },
        ];
      } else {
        state.colunasGrade = [
          {
            title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 0, visible: false,
          },
          {
            title: 'Emitente', dataIndex: 'emitente', key: 'Emitente', position: 1, visible: true, width: 250, align: 'left', ellipsis: true,
          },
          {
            title: 'Número', dataIndex: 'numeroNotaFiscal', key: 'NumeroNotaFiscal', position: 2, visible: true, width: 50, ordering: false, align: 'right', ellipsis: true,
          },
          {
            title: 'D.Emissão', dataIndex: 'dataEmissao', key: 'DataEmissao', position: 3, visible: true, ordering: false, align: 'center', width: 50, ellipsis: true,
          },
          {
            title: 'Valor R$', dataIndex: 'valor', key: 'Valor', position: 4, visible: true, ordering: false, align: 'right', width: 70, ellipsis: true,
          },
          {
            title: 'Chave Acesso', dataIndex: 'chaveAcesso', key: 'ChaveAcesso', position: 5, visible: true, width: 180, ordering: true, align: 'left', ellipsis: true,
          },
          {
            title: 'Ações', key: 'acoes', position: 6, visible: true, width: 50, align: 'center', ellipsis: true,
          },
        ];
      }
    }

    async function buscarNotas() {
      state.buscandoNotas = true;
      state.notasContraCnpj = await servicoNotaFiscal.obterNotasContraCnpj(props.empresa, state.valorBusca, state.filtroPeriodo, state.status, state.dataInicial, state.dataFinal);
      state.buscandoNotas = false;
    }

    onBeforeMount(async () => {
      preencherColunas();
      await buscarNotas();
    });

    watch(() => state.status, () => {
      buscarNotas();
    });

    return {
      props,
      state,
      UtilitarioMascara,
      UtilitarioData,
      importarNFe,
      buscarNotas,
      manifestarNFe,
      apresentarModalManifestacao,
      radioStyle,
      EFiltroPeriodoMovimentoComercial,
    };
  },
});
