
import {
  defineComponent, reactive, watch, provide,
} from 'vue';
import { Modal } from 'ant-design-vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import PreviewPdf from '@/core/components/Modal/PreviewPdf.vue';
import Card from '@/core/components/Tela/Card.vue';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import SelecionarCfop from '@/components/Cadastros/Tributacoes/Cfops/SelecionarCfop.vue';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import SelecionarModeloDocumentoFiscal from '@/components/Compartilhados/SelecionarModeloDocumentoFiscal.vue';
import SelecionarPessoa from '@/components/Cadastros/Pessoas/SelecionarPessoa.vue';
import SelecionarFormaPagamento from '@/components/Cadastros/Financeiro/SelecionarFormaPagamento.vue';
import SelecionarMarcadorNotaFiscal from '@/components/Fiscal/NotasFiscais/SelecionarMarcadorNotaFiscal.vue';
import {
  IRecebimentoMercadoria, IRecebimentoMercadoriaItem, IRecebimentoMercadoriaItemPreco,
} from '@/models/Entidades/Compras/IRecebimentoMercadoria';
import {
  INotaFiscal, INotaFiscalAnexo, INotaFiscalDetalhePagamento, INotaFiscalEmitente, INotaFiscalImpostoTotal, INotaFiscalIncidenciaCalculo, INotaFiscalItemEstoque, INotaFiscalMarcador, INotaFiscalTransporte, INotaFiscalTransporteVolume,
} from '@/models/Entidades/Fiscal/INotaFiscal';
import { ETipoEmissao } from '@/models/Enumeradores/Fiscal/ETipoEmissao';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { EStatusRecebimentoMercadoria } from '@/models/Enumeradores/Compras/EStatusRecebimentoMercadoria';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import ServicoRecebimentoMercadoria from '@/servicos/Compras/ServicoRecebimentoMercadoria';
import RecebimentoMercadoriasItens from '@/components/Compras/RecebimentosMercadorias/RecebimentoMercadoriasItens.vue';
import RecebimentoMercadoriasAtualizacaoPrecos from '@/components/Compras/RecebimentosMercadorias/RecebimentoMercadoriasAtualizacaoPrecos.vue';
import RecebimentoMercadoriasBuscaFinanceiro from '@/components/Compras/RecebimentosMercadorias/RecebimentoMercadoriasBuscaFinanceiro.vue';
import NotaFiscalImpostos from '@/components/Fiscal/NotasFiscais/NotaFiscalImpostos.vue';
import NotaFiscalFrete from '@/components/Fiscal/NotasFiscais/NotaFiscalFrete.vue';
import NotaFiscalDadosAdicionais from '@/components/Fiscal/NotasFiscais/NotaFiscalDadosAdicionais.vue';
import NotaFiscalDetalhesPagamento from '@/components/Fiscal/NotasFiscais/NotaFiscalDetalhesPagamento.vue';
import NotaFiscalAnexos from '@/components/Fiscal/NotasFiscais/NotaFiscalAnexos.vue';
import NotaFiscalHistorico from '@/components/Fiscal/NotasFiscais/NotaFiscalHistorico.vue';
import { ETipoFrete } from '@/models/Enumeradores/Compartilhados/ETipoFrete';
import ServicoNotaFiscal from '@/servicos/Fiscal/ServicoNotaFiscal';
import { IDTONotaFiscalRegistroAtividade } from '@/models/DTO/Fiscal/NotasFiscais/IDTONotaFiscalRegistroAtividade';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { EOrigemRecebimentoMercadoria } from '@/models/Enumeradores/Compras/EOrigemRecebimentoMercadoria';
import { EOrigemNotaFiscal } from '@/models/Enumeradores/Fiscal/EOrigemNotaFiscal';
import { ETipoOperacao } from '@/models/Enumeradores/Fiscal/ETipoOperacao';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import { EOrigemValorItem } from '@/models/Enumeradores/Compartilhados/EOrigemValorItem';
import ServicoCalculosComerciais from '@/servicos/ServicoCalculosComerciais';
import { ISalvamentoAutomatico } from '@/models/Entidades/MeuSistema/ISalvamentoAutomatico';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import storeSistema from '@/store/storeSistema';
import ServicoCfop from '@/servicos/Cadastros/Tributacoes/ServicoCfop';
import { ITipoCusto } from '@/models/Entidades/Cadastros/Precificacao/ITipoCusto';
import ServicoTipoCusto from '@/servicos/Cadastros/Precificacao/ServicoTipoCusto';
import ServicoTabelaPreco from '@/servicos/Cadastros/Precificacao/ServicoTabelaPreco';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { IEstoque } from '@/models/Entidades/Cadastros/Estoques/IEstoque';
import { ETipoMovimentoEstoque } from '@/models/Enumeradores/Estoques/MovimentoEstoque/ETipoMovimentoEstoque';
import ServicoEstoque from '@/servicos/Cadastros/Estoques/ServicoEstoque';
import ServicoProduto from '@/servicos/Cadastros/Produtos/ServicoProduto';
import ServicoFormaPagamento from '@/servicos/Cadastros/Financeiro/ServicoFormaPagamento';
import { IFormaPagamento } from '@/models/Entidades/Cadastros/Financeiro/IFormaPagamento';
import { ETipoMovimentoComercial } from '@/core/models/Enumeradores/ETipoMovimentoComercial';
import { ETipoRelacaoPessoa } from '@/models/Enumeradores/Cadastros/Pessoas/ETipoRelacaoPessoa';
import { IArquivoPdf } from '@/core/models/IArquivoPdf';
import { useFocusBase } from '@/core/composables/FocusBase';
import { ICfop } from '@/models/Entidades/Cadastros/Tributacoes/ICfop';
import NotaFiscalDescontosAcrescimos from '@/components/Fiscal/NotasFiscais/NotaFiscalDescontosAcrescimos.vue';

export default defineComponent({
  name: 'RecebimentoMercadoriasModal',
  props: {
    visivel: {
      type: Boolean,
    },
    origem: {
      type: Number,
      required: true,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
    recebimentoMercadoriaImportacao: {
      type: Object as () => IRecebimentoMercadoria,
    },
  },
  components: {
    RequisicaoModal,
    PreviewPdf,
    Card,
    Icone,
    SelecionarData,
    SelecionarCfop,
    SelecionarModeloDocumentoFiscal,
    SelecionarPessoa,
    SelecionarFormaPagamento,
    SelecionarMarcadorNotaFiscal,
    RecebimentoMercadoriasItens,
    RecebimentoMercadoriasAtualizacaoPrecos,
    RecebimentoMercadoriasBuscaFinanceiro,
    NotaFiscalDescontosAcrescimos,
    NotaFiscalImpostos,
    NotaFiscalFrete,
    NotaFiscalDadosAdicionais,
    NotaFiscalDetalhesPagamento,
    NotaFiscalAnexos,
    NotaFiscalHistorico,
  },
  emits: ['update:operacao', 'update:visivel', 'confirmacao', 'concluirEtapa', 'retornarEtapa', 'excluir', 'cancelar', 'imprimir'],
  setup(props, { emit }) {
    const servicoRecebimentoMercadoria = new ServicoRecebimentoMercadoria();
    const servicoCfop = new ServicoCfop();
    const servicoFormaPagamento = new ServicoFormaPagamento();
    servicoCfop.requisicaoSistema();

    const servicoTipoCusto = new ServicoTipoCusto();
    servicoTipoCusto.requisicaoSistema();

    const servicoTabelaPreco = new ServicoTabelaPreco();
    servicoTabelaPreco.requisicaoSistema();

    const servicoNotaFiscal = new ServicoNotaFiscal();
    servicoNotaFiscal.requisicaoSistema();

    const servicoEstoque = new ServicoEstoque();
    servicoEstoque.requisicaoSistema();

    const servicoCalculosComerciais = new ServicoCalculosComerciais();
    const servicoSistema = new ServicoSistema();

    const servicoProduto = new ServicoProduto();
    servicoProduto.requisicaoSistema();

    let debounceSalvamentoAutomatico = 0;
    let debounceAtualizacaoTotalizadores = 0;
    let debounceAtualizacaoCustoPreco = 0;
    let debounceBuscaFinanceiro = 0;
    const {
      telaBase, obterPermissoes, preencherEmpresasDisponiveis,
      preencherPermissoesDados, filtrarPermissaoDadosUsuarioMultiEmpresas, apresentarMensagemSucesso, apresentarMensagemAlerta, apresentarMensagemErro,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
    } = useModalBase(props, emit);

    const {
      focusBase, focusElemento, proximoElemento, elementoAnterior,
    } = useFocusBase();
    focusBase.classElementos = 'ss-recebimento-mercadorias-dados-principais';

    telaBase.identificadorRecurso = 'RECEBIMENTOS_MERCADORIAS';
    telaBase.identificadorPermissao = 'PER_RECEBIMENTOS_MERCADORIAS';

    const state = reactive({
      recebimento: {} as IRecebimentoMercadoria,
      tiposCustosCadastrados: [] as ITipoCusto[],
      codigoCfopFornecedor: 0,
      recuperarSalvamentoAutomatico: false,
      salvamentoAutomatico: {} as ISalvamentoAutomatico,
      marcadores: [] as string[],
      registrosAtividade: [] as IDTONotaFiscalRegistroAtividade[],
      incidenciasCalculo: [] as INotaFiscalIncidenciaCalculo[],
      estoquesDisponiveis: [] as IEstoque[],
      formaPagamento: {} as IFormaPagamento,
      cfopSelecionado: {} as ICfop,
      telaMobile: false,
      totalDetalhesPagamento: 0,
      editavel: false,
      exibirLancamentoItem: false,
      sincronizando: false,
      sincronizandoItem: false,
      novoItem: false,
      chaveItens: 0,
      importacaoRecebimentoMercadoria: false,
      renderizarTela: false,
      arquivosPdf: [] as IArquivoPdf[],
      apresentarBuscaFinanceiro: false,
      carregandoItensPelaImportacao: false,
      valorTotalImpostos: 0,
    });

    provide('RMERCADORIA_INCIDENCIA_CALCULO', state.incidenciasCalculo);

    function obtemIdentificadorSalvamentoAutomatico(): string {
      return `${telaBase.identificadorRecurso}_${state.recebimento.codigo}`;
    }

    function validarNumeroNota() {
      if (state.recebimento.notaFiscal.numeroNotaFiscal < 0) {
        state.recebimento.notaFiscal.numeroNotaFiscal = 0;
      }
    }

    async function salvamentoAutomatico() {
      if (state.recebimento.codigo > 0) {
        clearTimeout(debounceSalvamentoAutomatico);
        debounceSalvamentoAutomatico = setTimeout(async () => {
          const identificador = obtemIdentificadorSalvamentoAutomatico();
          state.salvamentoAutomatico.data = UtilitarioData.obterDataAtual();
          state.salvamentoAutomatico.codigoEmpresa = state.recebimento.codigoEmpresa;
          state.salvamentoAutomatico.codigoUsuario = storeSistema.getters.codigoUsuarioAutenticado();
          state.salvamentoAutomatico.identificador = identificador;
          state.salvamentoAutomatico.dados = JSON.stringify(state.recebimento);
          // Salva informações - LocalStorage
          await servicoSistema.salvamentoAutomaticoLocalStorage(`${identificador}_${state.salvamentoAutomatico.codigoUsuario}`, state.salvamentoAutomatico);
          // Salva informações - API Servidor
          const retornoSalvamento = await servicoSistema.salvamentoAutomaticoApi(state.salvamentoAutomatico);
          if (retornoSalvamento.status === EStatusRetornoRequisicao.Sucesso) {
            state.salvamentoAutomatico.codigo = retornoSalvamento.codigoRegistro;
          }
          state.recuperarSalvamentoAutomatico = false;
        }, 1000);
      }
    }

    function atualizarChaveAcesso() {
      state.recebimento.notaFiscal.chaveAcesso = UtilitarioGeral.extrairNumeros(state.recebimento.notaFiscal.chaveAcesso);
    }

    async function estrategiaAtualizacaoCfop() {
      if (UtilitarioGeral.validaCodigo(state.recebimento.codigoCfop)) {
        const cfop = await servicoCfop.obter(state.recebimento.codigoCfop, state.recebimento.codigoEmpresa);
        state.cfopSelecionado = cfop;
        state.codigoCfopFornecedor = await servicoCfop.obterIdentificador(servicoCfop.inverterCfop(cfop.codigoCfop));

        for (let index = 0; index < state.recebimento.itens.length; index += 1) {
          state.recebimento.itens[index].itemNotaFiscal.impostos.codigoCfop = state.recebimento.codigoCfop;
        }
      }
    }

    async function atualizarFormaPagamento() {
      if (state.recebimento.codigoFormaPagamento === 0 || state.recebimento.codigoFormaPagamento === undefined) {
        state.formaPagamento = {} as IFormaPagamento;
      } else if (state.recebimento.codigoFormaPagamento !== 0 && state.recebimento.codigoFormaPagamento !== undefined && state.recebimento.codigoFormaPagamento !== state.formaPagamento.codigo) {
        state.formaPagamento = await servicoFormaPagamento.obter(state.recebimento.codigoFormaPagamento);
        state.formaPagamento.tiposDocumentosFinanceiro = await servicoFormaPagamento.obterTiposDocumentos(state.recebimento.codigoFormaPagamento, state.recebimento.codigoEmpresa);
        state.formaPagamento.tabelasPreco = await servicoFormaPagamento.obterTabelasPreco(state.recebimento.codigoFormaPagamento, state.recebimento.codigoEmpresa);
      }
      salvamentoAutomatico();
    }

    async function preencherEstoquesDisponiveisItem(index: number) {
      state.recebimento.itens[index].itemNotaFiscal.estoquesDisponiveis = await servicoProduto.PreencherEstoquesProduto(state.recebimento.itens[index].itemNotaFiscal.codigoProdutoDefinicao, state.recebimento.codigoEmpresa, state.estoquesDisponiveis);
      state.recebimento.itens[index].itemNotaFiscal.estoques.forEach((e, indexE) => {
        let existe = false;
        state.recebimento.itens[index].itemNotaFiscal.estoquesDisponiveis.forEach((ed) => {
          if (ed.codigo === e.codigoEstoque) {
            existe = true;
          }
        });
        if (!existe) {
          state.recebimento.itens[index].itemNotaFiscal.estoques.splice(indexE, 1);
        }
      });
      if (state.recebimento.itens[index].itemNotaFiscal.estoquesDisponiveis.length > 0) {
        if (state.recebimento.itens[index].itemNotaFiscal.estoques.length > 0) {
          let qtdLancada = 0;
          state.recebimento.itens[index].itemNotaFiscal.estoques.forEach((e) => {
            qtdLancada += e.quantidade;
          });
          if (qtdLancada !== state.recebimento.itens[index].itemNotaFiscal.quantidade) {
            const diferenca = state.recebimento.itens[index].itemNotaFiscal.quantidade - qtdLancada;
            if (diferenca > 0) {
              state.recebimento.itens[index].itemNotaFiscal.estoques[0].quantidade += diferenca;
            } else {
              state.recebimento.itens[index].itemNotaFiscal.estoques[0].quantidade -= Math.abs(diferenca);
            }
          }
        } else {
          const estoqueItem = {} as INotaFiscalItemEstoque;
          estoqueItem.codigoEmpresa = state.recebimento.codigoEmpresa;
          estoqueItem.codigoEstoque = state.recebimento.itens[index].itemNotaFiscal.estoquesDisponiveis[0].codigo;
          estoqueItem.codigoCentroCusto = state.recebimento.itens[index].itemNotaFiscal.estoquesDisponiveis[0].codigoCentroCustoPadrao;
          estoqueItem.codigoNotaFiscalItem = state.recebimento.itens[index].itemNotaFiscal.codigo;
          estoqueItem.quantidade = state.recebimento.itens[index].itemNotaFiscal.quantidade;
          state.recebimento.itens[index].itemNotaFiscal.estoques.push(estoqueItem);
        }
      }
    }

    async function obterEstoquesDisponiveis() {
      if (state.recebimento.notaFiscal.emitente.codigoPessoa > 0 && state.recebimento.notaFiscal.emitente.codigoPessoa !== undefined) {
        state.estoquesDisponiveis = await servicoEstoque.obterEstoquesDisponiveis(state.recebimento.codigoEmpresa, state.recebimento.notaFiscal.emitente.codigoPessoa, ETipoMovimentoEstoque.Entrada);
      }
      state.recebimento.itens.forEach((item, index) => {
        preencherEstoquesDisponiveisItem(index);
      });
      salvamentoAutomatico();
    }

    function obterDescricaoStatus(status: EStatusRecebimentoMercadoria) {
      switch (status) {
        case EStatusRecebimentoMercadoria.EmDigitacao:
          return 'Em digitação';
        case EStatusRecebimentoMercadoria.AguardandoConferencia:
          return 'Aguardando conferência';
        case EStatusRecebimentoMercadoria.ConferenciaEmAndamento:
          return 'Conferência em andamento';
        case EStatusRecebimentoMercadoria.Conferido:
          return 'Conferido';
        case EStatusRecebimentoMercadoria.Concluido:
          return 'Concluído';
        case EStatusRecebimentoMercadoria.Cancelado:
          return 'Cancelado';
        default:
          return 'Devolvido';
      }
    }

    function obterCorStatus(status: EStatusRecebimentoMercadoria) {
      switch (status) {
        case EStatusRecebimentoMercadoria.EmDigitacao:
          return '#969696';
        case EStatusRecebimentoMercadoria.AguardandoConferencia:
          return '#d6c720';
        case EStatusRecebimentoMercadoria.ConferenciaEmAndamento:
          return '#f54e1b';
        case EStatusRecebimentoMercadoria.Conferido:
          return '#25b818';
        case EStatusRecebimentoMercadoria.Concluido:
          return '#1843b8';
        case EStatusRecebimentoMercadoria.Cancelado:
          return '#cf2751';
        default:
          return '#a737db';
      }
    }

    async function limparTela() {
      state.importacaoRecebimentoMercadoria = false;
      state.carregandoItensPelaImportacao = false;
      state.apresentarBuscaFinanceiro = false;
      state.recuperarSalvamentoAutomatico = false;
      state.salvamentoAutomatico = {} as ISalvamentoAutomatico;
      state.salvamentoAutomatico.codigo = 0;
      state.incidenciasCalculo = servicoRecebimentoMercadoria.obterIncidenciasNotaFiscalRecebimentoPadrao();
      state.recebimento = {} as IRecebimentoMercadoria;
      state.recebimento.codigo = 0;
      state.recebimento.codigoEmpresa = props.operacao.empresaSelecionada;
      state.recebimento.codigoFormaPagamento = 0;
      state.recebimento.dataCriacao = UtilitarioData.obterDataAtual();
      state.recebimento.origem = props.origem;
      state.recebimento.status = EStatusRecebimentoMercadoria.EmDigitacao;
      state.recebimento.itens = [] as IRecebimentoMercadoriaItem[];
      state.recebimento.notaFiscal = {} as INotaFiscal;
      state.recebimento.notaFiscal.codigoEmpresa = state.recebimento.codigoEmpresa;
      state.recebimento.notaFiscal.pessoaIdentificada = true;
      state.recebimento.notaFiscal.origemNotaFiscal = EOrigemNotaFiscal.RecebimentoMercadoria;
      state.recebimento.notaFiscal.tipoOperacao = ETipoOperacao.Saida;
      state.recebimento.notaFiscal.tipoFrete = ETipoFrete.SemOcorrenciaTransporte;
      state.recebimento.notaFiscal.tipoEmissao = ETipoEmissao.EmissaoTerceiro;
      state.recebimento.notaFiscal.modelo = '55';
      state.recebimento.notaFiscal.dataEmissao = UtilitarioData.obterDataAtual();
      state.recebimento.notaFiscal.dataEntradaSaida = UtilitarioData.obterDataAtual();
      state.recebimento.notaFiscal.emitente = {} as INotaFiscalEmitente;
      state.recebimento.notaFiscal.emitente.regimeTributario = 1;
      state.recebimento.notaFiscal.emitente.codigoPessoa = 0;
      state.recebimento.notaFiscal.totalImpostos = {} as INotaFiscalImpostoTotal;
      state.recebimento.notaFiscal.transporte = {} as INotaFiscalTransporte;
      state.recebimento.notaFiscal.transporte.codigoTransportadora = 0;
      state.recebimento.notaFiscal.transporte.volumes = [] as INotaFiscalTransporteVolume[];
      state.recebimento.notaFiscal.totalDesconto = 0;
      state.recebimento.notaFiscal.totalFrete = 0;
      state.recebimento.notaFiscal.totalNotaFiscal = 0;
      state.recebimento.notaFiscal.totalOutrasDespesas = 0;
      state.recebimento.notaFiscal.totalProdutos = 0;
      state.recebimento.notaFiscal.totalSeguro = 0;
      state.recebimento.notaFiscal.totalImpostos.valorAproximadoTributosTotal = 0;
      state.recebimento.notaFiscal.anexos = [] as INotaFiscalAnexo[];
      state.recebimento.notaFiscal.detalhesPagamento = [] as INotaFiscalDetalhePagamento[];
      state.recebimento.itens = [] as IRecebimentoMercadoriaItem[];
      state.cfopSelecionado = {} as ICfop;
      state.cfopSelecionado.codigo = 0;
      state.cfopSelecionado.codigoCfop = 0;
      state.marcadores = [] as string[];
      state.registrosAtividade = [] as IDTONotaFiscalRegistroAtividade[];
      state.estoquesDisponiveis = [] as IEstoque[];
      state.editavel = false;
      state.exibirLancamentoItem = false;
      state.sincronizando = false;
      state.sincronizandoItem = false;
      state.formaPagamento = {} as IFormaPagamento;
      state.valorTotalImpostos = 0;
    }

    function obtemPrecoCustoItem(indexItemRecebimento: number, codigoTipoCusto: number): number {
      const custoItemRecebimento = state.recebimento.itens[indexItemRecebimento].custos.find((c) => c.codigoTipoCusto === codigoTipoCusto);
      if (custoItemRecebimento !== undefined) {
        return custoItemRecebimento.valorCusto;
      }
      return 0;
    }

    async function atualizarCustoEPrecoVenda() {
      for (let index = 0; index < state.recebimento.itens.length; index += 1) {
        // Regras para cálculo do Custo e Preço Venda
        if (state.recebimento.itens[index].itemNotaFiscal.quantidade > 0 && state.recebimento.itens[index].itemNotaFiscal.valorTotal > 0) {
          const retornosCalculoCusto = await servicoCalculosComerciais.calcularCustoItemRecebimento(state.recebimento.itens[index], state.tiposCustosCadastrados);

          if (UtilitarioGeral.validaLista(retornosCalculoCusto)) {
            state.recebimento.itens[index].retornoCalculosCustos = retornosCalculoCusto;

            for (let indexCalculoCusto = 0; indexCalculoCusto < retornosCalculoCusto.length; indexCalculoCusto += 1) {
              if (retornosCalculoCusto[indexCalculoCusto].sucesso) {
                const indexItem = state.recebimento.itens[index].custos.findIndex((c) => c.codigoTipoCusto === retornosCalculoCusto[indexCalculoCusto].codigoTipoCusto);
                if (indexItem >= 0) {
                  state.recebimento.itens[index].custos[indexItem].valorCusto = retornosCalculoCusto[indexCalculoCusto].valorCusto;
                }
              }
            }
          } else {
            state.recebimento.itens[index].retornoCalculosCustos = [];
          }

          if (UtilitarioGeral.validaLista(state.recebimento.itens[index].precos)) {
            for (let indexPrecos = 0; indexPrecos < state.recebimento.itens[index].precos.length; indexPrecos += 1) {
              const precoCusto = obtemPrecoCustoItem(index, state.recebimento.itens[index].precos[indexPrecos].itemTabelaPreco.codigoTipoCusto);
              state.recebimento.itens[index].precos[indexPrecos].precoVenda = servicoCalculosComerciais.calculaPrecoVenda(precoCusto, state.recebimento.itens[index].precos[indexPrecos].markup);
            }
          } else {
            const itensTabelaPreco = await servicoTabelaPreco.obterTabelasProdutosDefinicoes([state.recebimento.codigoEmpresa], [state.recebimento.itens[index].itemNotaFiscal.codigoProdutoDefinicao], [], true);
            if (UtilitarioGeral.validaLista(itensTabelaPreco)) {
              const itensPrecosRecebimento: IRecebimentoMercadoriaItemPreco[] = [];
              for (let indexItensTabela = 0; indexItensTabela < itensTabelaPreco.length; indexItensTabela += 1) {
                const itemRecebimentoMercadoriaPreco: IRecebimentoMercadoriaItemPreco = {} as IRecebimentoMercadoriaItemPreco;
                itemRecebimentoMercadoriaPreco.codigo = 0;
                itemRecebimentoMercadoriaPreco.codigoRecebimentoMercadoriaItem = 0;
                itemRecebimentoMercadoriaPreco.codigoTabelaPrecoItemProduto = itensTabelaPreco[indexItensTabela].codigo;
                itemRecebimentoMercadoriaPreco.itemTabelaPreco = itensTabelaPreco[indexItensTabela];
                itemRecebimentoMercadoriaPreco.markup = itensTabelaPreco[indexItensTabela].markup;
                const precoCusto = obtemPrecoCustoItem(index, itemRecebimentoMercadoriaPreco.itemTabelaPreco.codigoTipoCusto);
                itemRecebimentoMercadoriaPreco.precoVenda = servicoCalculosComerciais.calculaPrecoVenda(precoCusto, itemRecebimentoMercadoriaPreco.markup);
                itemRecebimentoMercadoriaPreco.atualizarPreco = true;
                itensPrecosRecebimento.push(itemRecebimentoMercadoriaPreco);
              }

              state.recebimento.itens[index].precos = itensPrecosRecebimento;
            }
          }
        }
      }
    }

    async function atualizarValoresTotais() {
      clearTimeout(debounceAtualizacaoTotalizadores);
      debounceAtualizacaoTotalizadores = setTimeout(async () => {
        state.recebimento.notaFiscal.totalProdutos = 0;
        state.recebimento.notaFiscal.totalDesconto = 0;
        state.recebimento.notaFiscal.totalImpostos.baseCalculoIcmsTotal = 0;
        state.recebimento.notaFiscal.totalImpostos.valorIcmsTotal = 0;
        state.recebimento.notaFiscal.totalImpostos.baseCalculoIcmsSubstituicaoTotal = 0;
        state.recebimento.notaFiscal.totalImpostos.valorIcmsSubstituicaoTotal = 0;
        state.recebimento.notaFiscal.totalImpostos.baseCalculoIpiTotal = 0;
        state.recebimento.notaFiscal.totalImpostos.valorIpiTotal = 0;
        state.recebimento.notaFiscal.totalImpostos.baseCalculoPisTotal = 0;
        state.recebimento.notaFiscal.totalImpostos.valorPisTotal = 0;
        state.recebimento.notaFiscal.totalImpostos.baseCalculoCofinsTotal = 0;
        state.recebimento.notaFiscal.totalImpostos.valorCofinsTotal = 0;
        state.valorTotalImpostos = 0;

        for (let index = 0; index < state.recebimento.itens.length; index += 1) {
          if (UtilitarioGeral.validaCodigo(state.recebimento.itens[index].itemNotaFiscal.codigoProdutoDefinicao)) {
            state.recebimento.itens[index].itemNotaFiscal.valorTotal = servicoCalculosComerciais.calculaValorTotal(state.recebimento.itens[index].itemNotaFiscal.quantidade, state.recebimento.itens[index].itemNotaFiscal.precoUnitario, state.recebimento.itens[index].itemNotaFiscal.valorDesconto, 0);
            if (state.recebimento.itens[index].itemNotaFiscal.compoeTotal) {
              state.recebimento.notaFiscal.totalProdutos += state.recebimento.itens[index].itemNotaFiscal.quantidade * state.recebimento.itens[index].itemNotaFiscal.precoUnitario;
              state.recebimento.notaFiscal.totalDesconto += state.recebimento.itens[index].itemNotaFiscal.valorDesconto;
            }

            if (!UtilitarioGeral.validaCodigo(state.recebimento.itens[index].codigoUnidadeNfFornecedor)) {
              state.recebimento.itens[index].codigoUnidadeNfFornecedor = state.recebimento.itens[index].itemNotaFiscal.codigoUnidade;
            }

            if (state.recebimento.itens[index].codigoUnidadeNfFornecedor === state.recebimento.itens[index].itemNotaFiscal.codigoUnidade) {
              state.recebimento.itens[index].quantidadeNfFornecedor = state.recebimento.itens[index].itemNotaFiscal.quantidade;
              state.recebimento.itens[index].valorUnitarioNfFornecedor = state.recebimento.itens[index].itemNotaFiscal.precoUnitario;
              state.recebimento.itens[index].descontoUnitarioNfFornecedor = state.recebimento.itens[index].itemNotaFiscal.valorDesconto;
              state.recebimento.itens[index].valorTotalNfFornecedor = state.recebimento.itens[index].itemNotaFiscal.valorTotal;
            }

            if (!UtilitarioGeral.validaCodigo(state.recebimento.itens[index].itemNotaFiscal.impostos.codigoCfop)) {
              state.recebimento.itens[index].itemNotaFiscal.impostos.codigoCfop = state.recebimento.codigoCfop;
            }

            if (!UtilitarioGeral.validaCodigo(state.recebimento.itens[index].cfopNfFornecedor)) {
              state.recebimento.itens[index].cfopNfFornecedor = state.codigoCfopFornecedor;
            }
          }
        }

        state.recebimento.notaFiscal.totalNotaFiscal = state.recebimento.notaFiscal.totalProdutos;

        for (let index = 0; index < state.recebimento.itens.length; index += 1) {
          if (UtilitarioGeral.validaCodigo(state.recebimento.itens[index].itemNotaFiscal.codigoProdutoDefinicao)) {
            const subTotalProduto = servicoCalculosComerciais.formatarValor2CasasDecimais((state.recebimento.itens[index].itemNotaFiscal.quantidade * state.recebimento.itens[index].itemNotaFiscal.precoUnitario));
            if (parseFloat((subTotalProduto).toFixed(2)) < parseFloat(((state.recebimento.itens[index].itemNotaFiscal.valorDesconto)).toFixed(2))) {
              state.recebimento.itens[index].itemNotaFiscal.valorDesconto = 0;
            }
            if (state.recebimento.itens[index].itemNotaFiscal.origemFrete === EOrigemValorItem.Rateio) {
              if (state.recebimento.notaFiscal.totalFrete > 0) {
                state.recebimento.itens[index].itemNotaFiscal.valorFrete = servicoCalculosComerciais.calculaValorRateio(subTotalProduto, state.recebimento.notaFiscal.totalProdutos, state.recebimento.notaFiscal.totalFrete);
              } else {
                state.recebimento.itens[index].itemNotaFiscal.valorFrete = 0;
              }
            }

            if (state.recebimento.itens[index].itemNotaFiscal.origemOutrasDespesas === EOrigemValorItem.Rateio) {
              if (state.recebimento.notaFiscal.totalOutrasDespesas > 0) {
                state.recebimento.itens[index].itemNotaFiscal.outrasDespesas = servicoCalculosComerciais.calculaValorRateio(subTotalProduto, state.recebimento.notaFiscal.totalProdutos, state.recebimento.notaFiscal.totalOutrasDespesas);
              } else {
                state.recebimento.itens[index].itemNotaFiscal.outrasDespesas = 0;
              }
            }

            if (state.recebimento.itens[index].itemNotaFiscal.origemSeguro === EOrigemValorItem.Rateio) {
              if (state.recebimento.notaFiscal.totalSeguro > 0) {
                state.recebimento.itens[index].itemNotaFiscal.valorSeguro = servicoCalculosComerciais.calculaValorRateio(subTotalProduto, state.recebimento.notaFiscal.totalProdutos, state.recebimento.notaFiscal.totalSeguro);
              } else {
                state.recebimento.itens[index].itemNotaFiscal.valorSeguro = 0;
              }
            }

            const retornoCalculoTributacoes = await servicoCalculosComerciais.calcularTributacoes(state.recebimento.itens[index].itemNotaFiscal, state.incidenciasCalculo);
            if (UtilitarioGeral.objetoValido(retornoCalculoTributacoes)) {
              state.recebimento.itens[index].itemNotaFiscal.impostos.icms.baseCalculoIcms = retornoCalculoTributacoes.icms.baseCalculoIcms;
              state.recebimento.itens[index].itemNotaFiscal.impostos.icms.valorIcms = retornoCalculoTributacoes.icms.valorIcms;

              state.recebimento.notaFiscal.totalImpostos.baseCalculoIcmsTotal += state.recebimento.itens[index].itemNotaFiscal.impostos.icms.baseCalculoIcms;
              state.recebimento.notaFiscal.totalImpostos.valorIcmsTotal += state.recebimento.itens[index].itemNotaFiscal.impostos.icms.valorIcms;

              state.recebimento.itens[index].itemNotaFiscal.impostos.st.baseCalculoSubstituicaoTributaria = retornoCalculoTributacoes.icms.baseCalculoSubstituicaoTributaria;
              state.recebimento.itens[index].itemNotaFiscal.impostos.st.valorSubstituicaoTributaria = retornoCalculoTributacoes.icms.valorSubstituicaoTributaria;

              state.recebimento.notaFiscal.totalImpostos.baseCalculoIcmsSubstituicaoTotal += state.recebimento.itens[index].itemNotaFiscal.impostos.st.baseCalculoSubstituicaoTributaria;
              state.recebimento.notaFiscal.totalImpostos.valorIcmsSubstituicaoTotal += state.recebimento.itens[index].itemNotaFiscal.impostos.st.valorSubstituicaoTributaria;

              state.recebimento.itens[index].itemNotaFiscal.impostos.ipi.baseCalculoIpi = retornoCalculoTributacoes.ipi.baseCalculo;
              state.recebimento.itens[index].itemNotaFiscal.impostos.ipi.valorIpi = retornoCalculoTributacoes.ipi.valor;

              state.recebimento.notaFiscal.totalImpostos.baseCalculoIpiTotal += state.recebimento.itens[index].itemNotaFiscal.impostos.ipi.baseCalculoIpi;
              state.recebimento.notaFiscal.totalImpostos.valorIpiTotal += state.recebimento.itens[index].itemNotaFiscal.impostos.ipi.valorIpi;

              state.recebimento.itens[index].itemNotaFiscal.impostos.pis.baseCalculoPis = retornoCalculoTributacoes.pis.baseCalculo;
              state.recebimento.itens[index].itemNotaFiscal.impostos.pis.valorPis = retornoCalculoTributacoes.pis.valor;

              state.recebimento.notaFiscal.totalImpostos.baseCalculoPisTotal += state.recebimento.itens[index].itemNotaFiscal.impostos.pis.baseCalculoPis;
              state.recebimento.notaFiscal.totalImpostos.valorPisTotal += state.recebimento.itens[index].itemNotaFiscal.impostos.pis.valorPis;

              state.recebimento.itens[index].itemNotaFiscal.impostos.cofins.baseCalculoCofins = retornoCalculoTributacoes.cofins.baseCalculo;
              state.recebimento.itens[index].itemNotaFiscal.impostos.cofins.valorCofins = retornoCalculoTributacoes.cofins.valor;

              state.recebimento.notaFiscal.totalImpostos.baseCalculoCofinsTotal += state.recebimento.itens[index].itemNotaFiscal.impostos.cofins.baseCalculoCofins;
              state.recebimento.notaFiscal.totalImpostos.valorCofinsTotal += state.recebimento.itens[index].itemNotaFiscal.impostos.cofins.valorCofins;
            }
          }
        }
        state.valorTotalImpostos = state.recebimento.notaFiscal.totalImpostos.valorIcmsTotal + state.recebimento.notaFiscal.totalImpostos.valorIcmsSubstituicaoTotal + state.recebimento.notaFiscal.totalImpostos.valorIpiTotal + state.recebimento.notaFiscal.totalImpostos.valorPisTotal + state.recebimento.notaFiscal.totalImpostos.valorCofinsTotal;
        state.recebimento.notaFiscal.totalNotaFiscal = state.recebimento.notaFiscal.totalProdutos;
        const incidenciaTotal = state.incidenciasCalculo.filter((c) => c.incidencia === 'TotalNota');
        incidenciaTotal.forEach((itemIncidencia) => {
          if (itemIncidencia.identificador === 'IPI' && itemIncidencia.valor) {
            state.recebimento.notaFiscal.totalNotaFiscal += state.recebimento.notaFiscal.totalImpostos.valorIpiTotal;
          }
          if (itemIncidencia.identificador === 'Frete' && itemIncidencia.valor) {
            state.recebimento.notaFiscal.totalNotaFiscal += state.recebimento.notaFiscal.totalFrete;
          }
          if (itemIncidencia.identificador === 'Seguro' && itemIncidencia.valor) {
            state.recebimento.notaFiscal.totalNotaFiscal += state.recebimento.notaFiscal.totalSeguro;
          }
          if (itemIncidencia.identificador === 'OutrasDespesas' && itemIncidencia.valor) {
            state.recebimento.notaFiscal.totalNotaFiscal += state.recebimento.notaFiscal.totalOutrasDespesas;
          }
          if (itemIncidencia.identificador === 'Desconto' && itemIncidencia.valor) {
            state.recebimento.notaFiscal.totalNotaFiscal -= state.recebimento.notaFiscal.totalDesconto;
          }
        });
        atualizarCustoEPrecoVenda();
        salvamentoAutomatico();
      }, 600);
    }

    async function atualizarDescontoGeral() {
      for (let index = 0; index < state.recebimento.itens.length; index += 1) {
        if (UtilitarioGeral.validaCodigo(state.recebimento.itens[index].itemNotaFiscal.codigoProdutoDefinicao)) {
          const subTotalProduto = servicoCalculosComerciais.formatarValor2CasasDecimais((state.recebimento.itens[index].itemNotaFiscal.quantidade * state.recebimento.itens[index].itemNotaFiscal.precoUnitario));

          if (state.recebimento.notaFiscal.totalDesconto > 0) {
            state.recebimento.itens[index].itemNotaFiscal.valorDesconto = servicoCalculosComerciais.calculaValorRateio(subTotalProduto, state.recebimento.notaFiscal.totalProdutos, state.recebimento.notaFiscal.totalDesconto);
          } else {
            state.recebimento.itens[index].itemNotaFiscal.valorDesconto = 0;
          }
        }
      }
      atualizarValoresTotais();
    }

    function preencherMarcadores() {
      const marcadores = [] as INotaFiscalMarcador[];

      state.marcadores.forEach((m) => {
        const marcador = {} as INotaFiscalMarcador;
        marcador.codigoNotaFiscal = state.recebimento.notaFiscal.codigo;
        marcador.marcador = m;
        marcador.ordem = marcadores.length + 1;
        marcadores.push(marcador);
      });
      return marcadores;
    }

    function atualizarTotalDetalhesPagamento() {
      state.totalDetalhesPagamento = 0;
      state.recebimento.notaFiscal.detalhesPagamento.forEach((d) => {
        state.totalDetalhesPagamento += d.valorTotal;
      });
    }

    async function obterMarcadores() {
      state.recebimento.notaFiscal.marcadores.forEach((m) => {
        state.marcadores.push(m.marcador);
      });
    }

    async function obterRecebimento(codigo: number) {
      telaBase.carregando = true;
      state.sincronizandoItem = true;
      const recebimento = await servicoRecebimentoMercadoria.obter(codigo, props.operacao.empresaSelecionada);
      recebimento.itens = await servicoRecebimentoMercadoria.obterItensRecebimentoCompletos(codigo, props.operacao.empresaSelecionada);
      if (UtilitarioGeral.validaLista(recebimento.notaFiscal.incidenciasCalculo)) {
        state.incidenciasCalculo = recebimento.notaFiscal.incidenciasCalculo;
      }
      telaBase.carregando = false;
      state.recebimento = recebimento;
      obterEstoquesDisponiveis();
      obterMarcadores();
      await estrategiaAtualizacaoCfop();

      const identificador = obtemIdentificadorSalvamentoAutomatico();
      const salvamentoAutomaticoApi = await servicoSistema.obterUltimoSalvamentoAutomaticoApi(state.recebimento.codigoEmpresa, storeSistema.getters.codigoUsuarioAutenticado(), identificador);
      const salvamentoAutomaticoLocalStorage = await servicoSistema.obterSalvamentoAutomaticoLocalStorage(`${identificador}_${storeSistema.getters.codigoUsuarioAutenticado()}`);
      state.salvamentoAutomatico = servicoSistema.obterSalvamentoMaisRecente(salvamentoAutomaticoApi, salvamentoAutomaticoLocalStorage);

      if (UtilitarioData.validaDataPreenchida(state.salvamentoAutomatico.data) && !UtilitarioData.validaDataPreenchida(state.recebimento.dataAlteracao)) {
        state.recuperarSalvamentoAutomatico = true;
      } else if (UtilitarioData.validaDataPreenchida(state.salvamentoAutomatico.data) && UtilitarioData.validaDataPreenchida(state.recebimento.dataAlteracao)) {
        if (UtilitarioData.verificaDataMaisRecente(state.salvamentoAutomatico.data, state.recebimento.dataAlteracao)) { state.recuperarSalvamentoAutomatico = true; }
      }

      state.chaveItens += 1;
      state.sincronizandoItem = false;
      state.registrosAtividade = await servicoNotaFiscal.obterRegistrosAtividade(recebimento.notaFiscal.codigo);
      atualizarTotalDetalhesPagamento();
      atualizarFormaPagamento();
      state.editavel = state.recebimento.status === EStatusRecebimentoMercadoria.EmDigitacao;
    }

    async function atualizarItem(index: number) {
      if (state.recebimento.codigo === undefined || state.recebimento.codigo === 0) return;

      await atualizarValoresTotais();

      if (state.recebimento.itens[index].codigo === undefined || state.recebimento.itens[index].codigo === 0) {
        state.recebimento.itens[index].codigoRecebimentoMercadoria = state.recebimento.codigo;
        state.recebimento.itens[index].itemNotaFiscal.codigoNotaFiscal = state.recebimento.notaFiscal.codigo;
      }
    }

    async function atualizarDetalhePagamento(index: number) {
      if (state.recebimento.codigo === undefined || state.recebimento.codigo === 0) return;
      atualizarTotalDetalhesPagamento();

      if (state.recebimento.notaFiscal.detalhesPagamento[index].codigo === undefined || state.recebimento.notaFiscal.detalhesPagamento[index].codigo === 0) {
        state.recebimento.notaFiscal.detalhesPagamento[index].codigoNotaFiscal = state.recebimento.notaFiscal.codigo;
      }
    }

    async function atualizarAnexo(index: number) {
      if (state.recebimento.codigo === undefined || state.recebimento.codigo === 0) return;

      if (state.recebimento.notaFiscal.anexos[index].codigo === undefined || state.recebimento.notaFiscal.anexos[index].codigo === 0) {
        state.recebimento.notaFiscal.anexos[index].codigoNotaFiscal = state.recebimento.notaFiscal.codigo;
      }
    }

    async function excluirItem() {
      if (state.recebimento.codigo === undefined || state.recebimento.codigo === 0) return;
      if (state.sincronizandoItem) return;

      atualizarValoresTotais();
    }

    async function excluirAnexo(codigoAnexo: number) {
      if (state.recebimento.codigo === undefined || state.recebimento.codigo === 0) return;

      await servicoNotaFiscal.excluirAnexo(codigoAnexo, state.recebimento.codigoEmpresa);
    }

    async function salvar(salvarNovo: boolean) {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      if (state.recebimento.notaFiscal.dataEmissao !== state.recebimento.notaFiscal.dataEntradaSaida && UtilitarioData.verificaDataMaisRecente(state.recebimento.notaFiscal.dataEmissao, state.recebimento.notaFiscal.dataEntradaSaida)) {
        apresentarMensagemAlerta('A data de emissão não pode ser posterior a data de entrada!');
        return;
      }
      apresentarBarraProgresso('Aguarde por favor... Estamos salvando o Recebimento de Mercadorias');
      state.recebimento.notaFiscal.codigoEmpresa = state.recebimento.codigoEmpresa;
      state.recebimento.notaFiscal.marcadores = preencherMarcadores();
      state.recebimento.notaFiscal.incidenciasCalculo = state.incidenciasCalculo;
      if (state.importacaoRecebimentoMercadoria) {
        retorno = await servicoRecebimentoMercadoria.incluir(state.recebimento);
        ocultarBarraProgresso();
        if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
          await servicoNotaFiscal.atualizarNotaContraCnpj(state.recebimento.codigoNotaCNPJ, state.recebimento.codigoEmpresa);
          apresentarMensagemSucesso('Recebimento concluído com sucesso!');
          emit('confirmacao');
          state.importacaoRecebimentoMercadoria = false;
          modalBase.computedVisivel = false;
        } else {
          apresentarRetornoRequisicao(retorno);
        }
      } else {
        if (state.recebimento.codigo > 0) {
          retorno = await servicoRecebimentoMercadoria.alterar(state.recebimento);
        } else {
          retorno = await servicoRecebimentoMercadoria.incluirCabecalho(state.recebimento);
        }
        ocultarBarraProgresso();
        if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
          emit('confirmacao');
          apresentarMensagemSucesso(retorno.mensagem);
          const identificador = obtemIdentificadorSalvamentoAutomatico();
          // Remove informações - LocalStorage
          servicoSistema.removerSalvamentoAutomaticoLocalStorage(`${identificador}_${storeSistema.getters.codigoUsuarioAutenticado()}`);
          // Remove informações - API Servidor
          await servicoSistema.removerSalvamentoAutomaticoApi(state.recebimento.codigoEmpresa, storeSistema.getters.codigoUsuarioAutenticado(), identificador);

          if (state.recebimento.codigo === 0) {
            await obterRecebimento(retorno.codigoRegistro);
          } else if (salvarNovo) {
            limparTela();
            const telaOperacao: ITelaOperacao = props.operacao;
            telaOperacao.codigoRegistro = 0;
            telaOperacao.tipoPermissaoDados = EPermissaoDados.Incluir;
            modalBase.computedOperacao = telaOperacao;
          } else {
            modalBase.computedVisivel = false;
          }
        } else {
          apresentarRetornoRequisicao(retorno);
        }
      }
    }

    function validarDetalhesPagamento(salvarNovo: boolean) {
      Modal.confirm({
        title: 'O total dos detalhes de pagamento está diferente do total do recebimento!',
        content: 'Realmente deseja salvar o recebimento assim?',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: () => {
          salvar(salvarNovo);
        },
      });
    }

    function validarCampos(salvarNovo: boolean) {
      if (!UtilitarioGeral.validaNumerico(state.recebimento.notaFiscal.numeroNotaFiscal) || state.recebimento.notaFiscal.numeroNotaFiscal === 0) {
        apresentarMensagemAlerta('O número da nota deve ser informado!');
        return;
      }

      if (!UtilitarioGeral.valorValido(state.recebimento.notaFiscal.serie)) {
        apresentarMensagemAlerta('A série deve ser informada!');
        return;
      }

      if (!UtilitarioGeral.valorValido(state.recebimento.notaFiscal.dataEmissao)) {
        apresentarMensagemAlerta('A data de emissão deve ser informada!');
        return;
      }

      if (!UtilitarioGeral.valorValido(state.recebimento.notaFiscal.dataEntradaSaida)) {
        apresentarMensagemAlerta('A data de entrada deve ser informada!');
        return;
      }

      if (!UtilitarioGeral.valorValido(state.recebimento.notaFiscal.naturezaOperacao)) {
        apresentarMensagemAlerta('A natureza da operação deve ser informada!');
        return;
      }

      if (!UtilitarioGeral.validaCodigo(state.recebimento.notaFiscal.emitente.codigoPessoa)) {
        apresentarMensagemAlerta('O emitente deve ser informado!');
        return;
      }

      if (!UtilitarioGeral.validaCodigo(state.recebimento.codigoFormaPagamento)) {
        apresentarMensagemAlerta('A forma de pagamento deve ser informado!');
        return;
      }

      if (state.recebimento.origem !== EOrigemRecebimentoMercadoria.Manual && (state.recebimento.notaFiscal.chaveAcesso === '' || state.recebimento.notaFiscal.chaveAcesso === undefined)) {
        apresentarMensagemAlerta('A Chave de Acesso deve ser informada!');
        return;
      }

      const indexLinhaBranco = state.recebimento.itens.findIndex((c) => c.itemNotaFiscal.codigoProdutoDefinicao === undefined || c.itemNotaFiscal.codigoProdutoDefinicao === 0);
      if (indexLinhaBranco >= 0) {
        state.recebimento.itens.splice(indexLinhaBranco, 1);
      }
      if (state.recebimento.notaFiscal.totalNotaFiscal > 0 && state.totalDetalhesPagamento !== state.recebimento.notaFiscal.totalNotaFiscal) {
        validarDetalhesPagamento(salvarNovo);
      } else {
        salvar(salvarNovo);
      }
    }

    function apresentarBuscarVincularTitulos() {
      state.apresentarBuscaFinanceiro = !state.apresentarBuscaFinanceiro;

      if (state.apresentarBuscaFinanceiro) {
        clearTimeout(debounceBuscaFinanceiro);
        debounceBuscaFinanceiro = setTimeout(() => {
          const element = document.getElementById('resultadoBuscaTitulosRecebimentoFinanceiro');
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 600);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      state.telaMobile = UtilitarioDispositivo.larguraTelaMobile();
      limparTela();
      state.editavel = true;
      if (modalBase.computedVisivel) {
        if (props.operacao.listaPermissoesDados.length > 0) {
          await preencherPermissoesDados(props.operacao.listaPermissoesDados);
        } else {
          await obterPermissoes(ETipoPermissao.Dados);
        }
        await preencherEmpresasDisponiveis();
        telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);
        state.tiposCustosCadastrados = await servicoTipoCusto.obterTodosTiposCustos([props.operacao.empresaSelecionada]);
        if (UtilitarioGeral.validaCodigo(props.operacao.codigoRegistro)) {
          await obterRecebimento(props.operacao.codigoRegistro);
        }

        if (props.recebimentoMercadoriaImportacao !== undefined) {
          if (UtilitarioGeral.objetoValido(props.recebimentoMercadoriaImportacao.notaFiscal)) {
            if (props.recebimentoMercadoriaImportacao.notaFiscal.numeroNotaFiscal > 0) {
              state.importacaoRecebimentoMercadoria = true;
              state.carregandoItensPelaImportacao = true;
              state.recebimento = props.recebimentoMercadoriaImportacao;
              atualizarTotalDetalhesPagamento();
              clearTimeout(debounceAtualizacaoCustoPreco);
              debounceAtualizacaoCustoPreco = setTimeout(async () => {
                await atualizarCustoEPrecoVenda();
                state.carregandoItensPelaImportacao = false;
              }, 900);
            }
          }
        }
      }
    });

    function obtemDescricaoIncidencia(identificador: string): string {
      if (identificador === 'OutrasDespesas') {
        return 'Outras Despesas';
      }

      return identificador;
    }

    function recuperarSalvamentoAutomatico() {
      if (UtilitarioGeral.valorValido(state.salvamentoAutomatico.dados)) {
        const recebimentoMercadoriaSalvoAutomatico = JSON.parse(state.salvamentoAutomatico.dados);
        if (UtilitarioGeral.objetoValido(recebimentoMercadoriaSalvoAutomatico)) {
          state.recebimento = recebimentoMercadoriaSalvoAutomatico;
          state.recuperarSalvamentoAutomatico = false;
          apresentarMensagemSucesso('Informações recuperadas com sucesso!');
        }
      }
    }

    function concluirEtapa() {
      Modal.confirm({
        title: `Realmente deseja passar da etapa ${obterDescricaoStatus(state.recebimento.status)} para a etapa ${obterDescricaoStatus(state.recebimento.status + 1)}?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          const retorno = await servicoRecebimentoMercadoria.alterarEtapa(state.recebimento.codigo, state.recebimento.codigoEmpresa, state.recebimento.status + 1);
          if (retorno.status === 1) {
            state.recebimento.status += 1;
            emit('confirmacao');
          } else if (retorno.status === 2) {
            apresentarMensagemAlerta(retorno.mensagem);
          } else {
            apresentarMensagemErro(retorno.mensagem);
          }
        },
      });
    }

    function retornarEtapa() {
      Modal.confirm({
        title: `Realmente deseja passar da etapa ${obterDescricaoStatus(state.recebimento.status)} para a etapa ${obterDescricaoStatus(state.recebimento.status - 1)}?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          const retorno = await servicoRecebimentoMercadoria.alterarEtapa(state.recebimento.codigo, state.recebimento.codigoEmpresa, state.recebimento.status - 1);
          if (retorno.status === 1) {
            state.recebimento.status -= 1;
            emit('confirmacao');
          } else if (retorno.status === 2) {
            apresentarMensagemAlerta(retorno.mensagem);
          } else {
            apresentarMensagemErro(retorno.mensagem);
          }
        },
      });
    }

    function cancelar() {
      Modal.confirm({
        title: 'Realmente deseja cancelar o recebimento?',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          const retorno = await servicoRecebimentoMercadoria.alterarEtapa(state.recebimento.codigo, state.recebimento.codigoEmpresa, EStatusRecebimentoMercadoria.Cancelado);
          if (retorno.status === 1) {
            state.recebimento.status = EStatusRecebimentoMercadoria.Cancelado;
            emit('confirmacao');
          } else if (retorno.status === 2) {
            apresentarMensagemAlerta(retorno.mensagem);
          } else {
            apresentarMensagemErro(retorno.mensagem);
          }
        },
      });
    }

    function excluir() {
      Modal.confirm({
        title: 'Realmente deseja excluir o recebimento?',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          const retorno = await servicoRecebimentoMercadoria.excluirVarios(state.recebimento.codigoEmpresa, [state.recebimento.codigo]);
          if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
            apresentarMensagemSucesso('Recebimento excluído com sucesso!');
            emit('confirmacao');
            modalBase.computedVisivel = false;
          } else if (UtilitarioGeral.valorValido(retorno.mensagem)) {
            apresentarMensagemErro(retorno.mensagem);
          } else {
            apresentarMensagemErro('Não foi possível excluir o recebimento de mercadoria.');
          }
        },
      });
    }

    async function imprimir() {
      const retornoRelatorio = await servicoRecebimentoMercadoria.imprimirRecebimento(state.recebimento.codigo, state.recebimento.codigoEmpresa, ETipoArquivo.PDF);
      telaBase.carregando = false;
      if (retornoRelatorio.status === EStatusRetornoRequisicao.Sucesso) {
        state.arquivosPdf = [{ nome: '', link: retornoRelatorio.link } as IArquivoPdf];
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Erro) {
        apresentarMensagemErro(retornoRelatorio.mensagem);
      }
    }

    return {
      props,
      state,
      telaBase,
      modalBase,
      EPermissaoDados,
      ETipoEmissao,
      UtilitarioGeral,
      UtilitarioMascara,
      apresentarRetornoRequisicao,
      obterDescricaoStatus,
      obterCorStatus,
      atualizarValoresTotais,
      atualizarItem,
      atualizarDetalhePagamento,
      apresentarBuscarVincularTitulos,
      excluirItem,
      atualizarAnexo,
      excluirAnexo,
      validarCampos,
      obtemDescricaoIncidencia,
      atualizarChaveAcesso,
      atualizarDescontoGeral,
      salvamentoAutomatico,
      recuperarSalvamentoAutomatico,
      estrategiaAtualizacaoCfop,
      obterEstoquesDisponiveis,
      EStatusRecebimentoMercadoria,
      concluirEtapa,
      retornarEtapa,
      cancelar,
      excluir,
      imprimir,
      atualizarFormaPagamento,
      ETipoRelacaoPessoa,
      ETipoMovimentoComercial,
      validarNumeroNota,
      focusElemento,
      proximoElemento,
      elementoAnterior,
    };
  },
});
